// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList14900 = opt_data.frozenFollowers;
  var followerListLen14900 = followerList14900.length;
  for (var followerIndex14900 = 0; followerIndex14900 < followerListLen14900; followerIndex14900++) {
    var followerData14900 = followerList14900[followerIndex14900];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14900.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData14900.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList14908 = opt_data.followers;
  var followerListLen14908 = followerList14908.length;
  for (var followerIndex14908 = 0; followerIndex14908 < followerListLen14908; followerIndex14908++) {
    var followerData14908 = followerList14908[followerIndex14908];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14908.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData14908.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData14908.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData14908.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
